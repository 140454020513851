import React from 'react';
import GateKeeper from '../../../components/FirebaseAuth/GateKeeper';
import MembersLayout from '../../../components/Members/Layout';
import SendInvites from '../../../components/Members/Admin/SendInvites';
import Container from '../../../components/Container';
import { Tabs, TabPanel } from 'react-tabs';
import { Flex } from 'rebass';

import ManageUsers from '../../../components/Members/Admin/ManageUsers';
import MembersAdminSettings from '../../../components/Members/Admin/Settings';
import SEO from '../../../components/seo';
import { StyledTabList, StyledTab } from '../../../components/Tabs';

const MembersAdmin = () => (
  <GateKeeper onlyAdmins>
    <SEO title="Admin dashboard" />
    <MembersLayout goBackLink="/members" title="Admin dashboard">
      <Container py={3}>
        <Tabs>
          <StyledTabList mb={[4, 5]}>
            <Flex>
              <StyledTab>Manage users</StyledTab>
              <StyledTab>Send invites</StyledTab>
              <StyledTab>Settings</StyledTab>
            </Flex>
          </StyledTabList>
          <TabPanel>
            <ManageUsers />
          </TabPanel>
          <TabPanel>
            <SendInvites />
          </TabPanel>
          <TabPanel>
            <MembersAdminSettings />
          </TabPanel>
        </Tabs>
      </Container>
    </MembersLayout>
  </GateKeeper>
);

export default MembersAdmin;
