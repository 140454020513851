import React, { useRef } from 'react';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import Text from '../../../../../Text';
import { displayFields } from '../utils';
import { FiSquare, FiCheckSquare } from 'react-icons/fi';
import { resolveMember } from '../../../../utils';

const Wrapper = styled(Box).attrs({
  p: 3,
})`
  border-bottom: ${({ theme }) => theme.border};
`;

const Property = styled(Text).attrs({ variant: 'secondary', p: 3 })`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AdminMemberItem = ({ member, isSelected, onSelect, ...props }) => {
  const iconProps = {
    size: 24,
    onClick: onSelect,
    style: { display: 'inline-flex', cursor: 'pointer' },
  };
  const resolvedMember = resolveMember(member);
  const checkbox = useRef(null);
  const bg = isSelected ? 'grays.200' : 'white';
  return (
    <Wrapper {...props} bg={isSelected ? 'grays.200' : undefined}>
      <Flex m={-3} alignItems="center">
        <Text
          ref={checkbox}
          variant="secondary"
          p={3}
          bg={bg}
          css={{ display: 'inline-flex', position: 'sticky', left: 0 }}
        >
          {isSelected ? (
            <FiCheckSquare {...iconProps} />
          ) : (
            <FiSquare {...iconProps} />
          )}
        </Text>
        {displayFields.map((field, idx) => (
          <Property
            key={field.id}
            width={field.width}
            bg={bg}
            style={
              idx === 0
                ? { position: 'sticky', left: checkbox?.current?.scrollWidth }
                : {}
            }
          >
            {field.value(resolvedMember)}
          </Property>
        ))}
      </Flex>
    </Wrapper>
  );
};

export default AdminMemberItem;
