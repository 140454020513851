import React from 'react';
import Badge from '../../../../../Badge';
import { FiEdit } from 'react-icons/fi';
import { usePopoverState, PopoverDisclosure } from 'reakit/Popover';
import { Popover, PopoverItem, PopoverArrow } from '../../../../../Popover';
import Hr from '../../../../../Layout/Hr';
import MembersAdminSettingsMembershipLevelsItemEdit from './Edit';
import MembersAdminSettingsMembershipLevelsItemDelete from './Delete';

const MembersAdminSettingsMembershipLevelsItem = ({ doc, ...props }) => {
  const popover = usePopoverState();
  return (
    <>
      <PopoverDisclosure css={{ cursor: 'pointer' }} {...popover}>
        <Badge
          p={3}
          bg="grays.100"
          color="valhalla"
          css={{ display: 'flex', alignItems: 'center' }}
          {...props}
        >
          {doc.data().label}&nbsp;
          <FiEdit />
        </Badge>
      </PopoverDisclosure>
      <Popover {...popover}>
        <PopoverArrow {...popover} />
        <PopoverItem>
          <MembersAdminSettingsMembershipLevelsItemEdit doc={doc} />
        </PopoverItem>
        <Hr width={1} my={2} height={2} />
        <PopoverItem>
          <MembersAdminSettingsMembershipLevelsItemDelete doc={doc} />
        </PopoverItem>
      </Popover>
    </>
  );
};

export default MembersAdminSettingsMembershipLevelsItem;
