import React from 'react';
import useMembershipLevels from '../../../../../utils/hooks/members/useMembershipLevels';
import Card from '../../../../Card';
import Text from '../../../../Text';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import MembersAdminSettingsMembershipLevelsItem from './Item';
import MembersAdminSettingsMembershipLevelsCreate from './Create';
import Spinner from '../../../../Spinner';
import Alert from '../../../../Alert';

const Inner = styled(Box).attrs({ p: [3, 4] })``;

const MembersAdminSettingsMembershipLevels = () => {
  const { data, loading, error } = useMembershipLevels();
  return (
    <Card p={0} css={{ overflow: 'hidden' }}>
      <Inner>
        <Text fontSize={2} fontWeight="500" mb={3}>
          Membership levels
        </Text>
        {error && (
          <Alert type="danger">
            {error?.message || error || 'An unexpected error occurred'}
          </Alert>
        )}
        {loading && <Spinner />}
        <Flex m={-2} flexWrap="wrap">
          {data?.docs.map(doc => (
            <MembersAdminSettingsMembershipLevelsItem
              m={2}
              doc={doc}
              key={doc.id}
            />
          ))}
        </Flex>
      </Inner>
      <Inner bg="grays.200">
        <MembersAdminSettingsMembershipLevelsCreate />
      </Inner>
    </Card>
  );
};

export default MembersAdminSettingsMembershipLevels;
