import React, { useState } from 'react';
import { useDialogState, DialogDisclosure } from 'reakit/Dialog';
import { DialogBackdrop, Dialog } from '../../../../../../Dialog';
import ConfirmationPrompt from '../../../../ManageUsers/Toolbar/Edit/Prompt';
import Input from '../../../../../../Input';
import { Box, Flex } from 'rebass';
import Hr from '../../../../../../Layout/Hr';
import Button from '../../../../../../Button';
import useUpdateMembershipLevel from '../../../../../../../utils/hooks/members/useUpdateMembershipLevel';
import MembersAdminSettingsMembershipLevelsItemPrismicAlert from '../../PrismicAlert';
import { useCollection } from 'react-firebase-hooks/firestore';
import lazyFirebase from '../../../../../../../firebase/config';
import Alert from '../../../../../../Alert';

const MembersAdminSettingsMembershipLevelsItemEdit = ({ doc }) => {
  const dialog = useDialogState({ modal: true });
  const [data] = useCollection(
    dialog.visible
      ? lazyFirebase
          .firestore()
          .collection('members')
          .where('membershipLevel', '==', doc.id)
      : undefined,
  );
  const initialValue = doc?.data().label;
  const [input, setInput] = useState(initialValue);
  const [didConfirm, setDidConfirm] = useState(false);
  const [updateMembershipLevel] = useUpdateMembershipLevel({ id: doc.id });
  return (
    <>
      <DialogDisclosure {...dialog}>Rename</DialogDisclosure>
      <DialogBackdrop {...dialog}>
        <Dialog maxWidth={480} {...dialog}>
          {didConfirm ? (
            <ConfirmationPrompt
              onHide={() => {
                dialog.hide();
                setDidConfirm(false);
              }}
              onConfirm={() => updateMembershipLevel({ label: input })}
              showAlert={didConfirm}
              confirmationMessage="Membership level is being renamed"
            >
              <MembersAdminSettingsMembershipLevelsItemPrismicAlert
                label={input}
              />
            </ConfirmationPrompt>
          ) : (
            <form onSubmit={e => e.preventDefault()}>
              <Box p={[3, 4]}>
                <Input
                  inline
                  label="Rename membership level"
                  onChange={e => setInput(e.target.value)}
                  value={input}
                  error={!input.length ? 'Required' : undefined}
                  touched={input !== initialValue}
                />
                {data?.size > 0 && (
                  <Alert type="warning" mt={3}>
                    This membership level has {data?.size} member
                    {data.size > 1 ? 's' : ''}
                  </Alert>
                )}
              </Box>
              <Hr width={1} height={2} />
              <Flex p={3} justifyContent="flex-end">
                <Button
                  disabled={!input.length}
                  variant="minimal"
                  onClick={() => setDidConfirm(true)}
                >
                  Continue
                </Button>
              </Flex>
            </form>
          )}
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

export default MembersAdminSettingsMembershipLevelsItemEdit;
