import React from 'react';
import { usePopoverState, PopoverDisclosure } from 'reakit/Popover';
import { Popover, PopoverArrow, PopoverItem } from '../../../../../../Popover';
import { FiEdit } from 'react-icons/fi';
import AdminManagerMembersToolbarEditMembershipLevel from '../MembershipLevel';
import Button from '../../../../../../Button';
import AdminManagerMembersToolbarEditMembershipExpiry from '../MembershipExpires';
import Hr from '../../../../../../Layout/Hr';
import AdminManagerMembersToolbarEditMembershipStatus from '../MemberStatus';
import { Box } from 'rebass';

const AdminManagerMembersToolbarEditButton = ({
  updateMember,
  selectedLength,
  updateByWhereList,
  ...props
}) => {
  const popover = usePopoverState({ placement: 'bottom-end' });
  return (
    <Box {...props}>
      <PopoverDisclosure {...popover}>
        <Button
          disabled={!selectedLength}
          variant="white"
          css={{ display: 'flex', alignItems: 'center' }}
          px={3}
          py={2}
        >
          <FiEdit size={16} />
          &nbsp; Edit
        </Button>
      </PopoverDisclosure>
      <Popover {...popover}>
        <PopoverArrow {...popover} />
        <PopoverItem>
          <AdminManagerMembersToolbarEditMembershipLevel
            selectedLength={selectedLength}
            updateByWhereList={updateByWhereList}
            onUpdate={updateMember}
          />
        </PopoverItem>
        <Hr width={1} height={2} my={2} />
        <PopoverItem>
          <AdminManagerMembersToolbarEditMembershipExpiry
            selectedLength={selectedLength}
            updateByWhereList={updateByWhereList}
            onUpdate={updateMember}
          />
        </PopoverItem>
        <Hr width={1} height={2} my={2} />
        <PopoverItem>
          <AdminManagerMembersToolbarEditMembershipStatus
            selectedLength={selectedLength}
            updateByWhereList={updateByWhereList}
            onUpdate={updateMember}
          />
        </PopoverItem>
      </Popover>
    </Box>
  );
};

export default AdminManagerMembersToolbarEditButton;
