import React, { useState } from 'react';
import { useDialogState, DialogDisclosure } from 'reakit/Dialog';
import { DialogBackdrop, Dialog } from '../../../../../../Dialog';
import ConfirmationPrompt from '../../../../ManageUsers/Toolbar/Edit/Prompt';
import useDeleteMembershipLevel from '../../../../../../../utils/hooks/members/useDeleteMembershipLevel';
import Text from '../../../../../../Text';
import Checkbox from '../../../../../../Checkbox';
import { useCollection } from 'react-firebase-hooks/firestore';
import lazyFirebase from '../../../../../../../firebase/config';
import Alert from '../../../../../../Alert';

const MembersAdminSettingsMembershipLevelsItemDelete = ({ doc }) => {
  const dialog = useDialogState({ modal: true });
  const [data] = useCollection(
    dialog.visible
      ? lazyFirebase
          .firestore()
          .collection('members')
          .where('membershipLevel', '==', doc.id)
      : undefined,
  );
  const [didConfirm, setDidConfirm] = useState(false);
  const [deleteMembershipLevel] = useDeleteMembershipLevel({ id: doc.id });
  return (
    <>
      <DialogDisclosure {...dialog}>Delete</DialogDisclosure>
      <DialogBackdrop {...dialog}>
        <Dialog maxWidth={480} {...dialog}>
          <ConfirmationPrompt
            onHide={() => {
              dialog.hide();
              setDidConfirm(false);
            }}
            onConfirm={() => deleteMembershipLevel({ id: doc.id })}
            confirmationMessage="Membership level is being deleted"
            disabled={!data || data?.size > 0 || !didConfirm}
          >
            <Text css={{ display: 'inline-flex' }}>
              Delete&nbsp;<Text fontWeight="500">{doc?.data()?.label}</Text>
            </Text>

            {data?.size > 0 ? (
              <Alert type="danger" mt={3}>
                This membership level has {data?.size} member
                {data.size > 1 ? 's' : ''}. Please change the membership levels
                of those members before deleting this one.
              </Alert>
            ) : (
              <Checkbox
                id="confirmation"
                checked={didConfirm}
                onChange={() => setDidConfirm(!didConfirm)}
                label="I understand this action cannot be undone."
              />
            )}
          </ConfirmationPrompt>
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

export default MembersAdminSettingsMembershipLevelsItemDelete;
