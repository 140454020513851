import React from 'react';
import styled from 'styled-components';

import AdminMemberItem from '../Item';
import Card from '../../../../../Card';
import AdminMemberListHeader from './Header';
import Spinner from '../../../../../Spinner';
import { Link } from 'gatsby';
import MembersEmptySlate from '../../../../EmptySlate';
import { minWidth } from 'styled-system';

const Wrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  ${({ isEmpty }) => isEmpty && 'overflow-x:auto;'};
  display: block;
  border-radius: 4px;
`;

const List = styled(Card).attrs(({ isEmpty }) => ({
  as: 'ul',
  minWidth: isEmpty ? undefined : [1280, null, null, 'unset'],
}))`
  ${minWidth}
  display: flex;
  flex-direction: column;
`;

const AdminMemberList = ({
  members,
  orderBy,
  onOrderBy,
  isLoading,
  selectedMembers,
  setSelectedMembers,
  updateByWhereList,
}) => {
  const allUids = members?.map(member => member.uid);
  const areAllMembersSelected =
    updateByWhereList ||
    (allUids?.length && allUids?.every(uid => selectedMembers.includes(uid)));
  const isEmpty = !isLoading && !members?.length;
  return (
    <Wrapper>
      <List isEmpty={isEmpty}>
        {!isEmpty && (
          <AdminMemberListHeader
            areAllMembersSelected={areAllMembersSelected}
            onSelect={() =>
              areAllMembersSelected
                ? setSelectedMembers([])
                : setSelectedMembers(allUids)
            }
            orderBy={orderBy}
            onOrderBy={onOrderBy}
          />
        )}
        {isLoading ? (
          <Spinner />
        ) : (
          members?.map(member => {
            const isSelected = selectedMembers.includes(member.uid);
            return (
              <Link
                key={member.uid}
                to={`/members/admin/member?uid=${member.uid}`}
              >
                <AdminMemberItem
                  isSelected={updateByWhereList || isSelected}
                  onSelect={e => {
                    e.preventDefault();
                    isSelected
                      ? setSelectedMembers(
                          selectedMembers.filter(uid => uid !== member.uid),
                        )
                      : setSelectedMembers([...selectedMembers, member.uid]);
                  }}
                  setSelectedMembers={setSelectedMembers}
                  as="li"
                  member={member}
                />
              </Link>
            );
          })
        )}
        {isEmpty && (
          <MembersEmptySlate
            maxWidth={480}
            mx="auto"
            p={[4, 5]}
            illustration="ghost"
            title="There’s nothing here…"
            description="Your filter did not match any members. Make sure all words are spelled correctly and try again. Keep in mind that some filters are case-sensitive."
          />
        )}
      </List>
    </Wrapper>
  );
};

export default AdminMemberList;
