import { Card } from 'rebass';
import styled from 'styled-components';

const Badge = styled(Card)`
  border-radius: 4px;
`;

Badge.defaultProps = {
  p: 2,
};

export default Badge;
