import React from 'react';
import MembersAdminSettingsMembershipLevels from './MembershipLevels';

const MembersAdminSettings = () => {
  return (
    <>
      <MembersAdminSettingsMembershipLevels />
    </>
  );
};

export default MembersAdminSettings;
