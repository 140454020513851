import useMembershipLevels from '../../utils/hooks/members/useMembershipLevels';

export const resolveMember = member => {
  const { data } = useMembershipLevels();
  let membershipLevel = member.membershipLevel;
  membershipLevel = data?.docs?.find(doc => doc.id === membershipLevel)?.data()
    ?.label;
  return {
    ...member,
    membershipLevel,
  };
};
