import React, { useState } from 'react';
import { useDialogState, DialogDisclosure } from 'reakit/Dialog';
import { Dialog, DialogBackdrop } from '../../../../../../Dialog';
import Select from '../../../../../../Select';
import Text from '../../../../../../Text';
import ConfirmationPrompt from '../Prompt';

const AdminManagerMembersToolbarEditMembershipStatus = ({
  onUpdate,
  selectedLength,
  updateByWhereList,
}) => {
  const [isActive, setActive] = useState(true);
  const dialog = useDialogState({ modal: true });
  return (
    <>
      <DialogDisclosure {...dialog}>Member status</DialogDisclosure>
      <DialogBackdrop {...dialog}>
        <Dialog maxWidth={480} {...dialog}>
          <ConfirmationPrompt
            confirmationMessage={`Member status is being updated`}
            onHide={dialog.hide}
            onConfirm={() => onUpdate({ isActive })}
          >
            <Text mb={3} fontWeight="500" fontSize={2}>
              Update member status for{' '}
              {updateByWhereList
                ? 'all members that match this set of filters'
                : `${selectedLength} member${selectedLength > 1 ? 's' : ''}`}
            </Text>
            <Select
              variant="secondary"
              value={isActive}
              onChange={e => setActive(e.target.value === 'true')}
            >
              {[
                { label: 'Active', value: true },
                { label: 'Paused', value: false },
              ].map(status => (
                <option key={status.label} value={status.value}>
                  {status.label}
                </option>
              ))}
            </Select>
          </ConfirmationPrompt>
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

export default AdminManagerMembersToolbarEditMembershipStatus;
