import { useState } from 'react';
import lazyFirebase from '../../../firebase/config';

const useUpdateMembershipLevel = ({ id }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const updateMembershipLevel = data =>
    lazyFirebase
      .firestore()
      .collection('membershipLevels')
      .doc(id)
      .set(data, { merge: true });
  const fn = _props => {
    setLoading(true);
    setData(null);
    setError(null);
    return updateMembershipLevel(_props)
      .then(res => {
        setLoading(false);
        setData(res || { data: {} });
      })
      .catch(e => {
        setLoading(false);
        setError(e);
      });
  };
  return [
    fn,
    {
      data,
      loading,
      error,
    },
  ];
};

export default useUpdateMembershipLevel;
