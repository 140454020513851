import React from 'react';
import { format } from 'date-fns';
import Badge from '../../../../../Badge';
import { FiX } from 'react-icons/fi';
import styled from 'styled-components';
import { Flex } from 'rebass';
import isEqual from 'lodash/isEqual';
import useMembershipLevels from '../../../../../../utils/hooks/members/useMembershipLevels';

const Wrapper = styled(Flex).attrs({ bg: 'grays.200' })`
  border-radius: 4px;
  flex-wrap: wrap;
`;

const displayFields = [
  {
    id: 'firstName',
    label: 'First name',
    value: value => value,
  },
  {
    id: 'lastName',
    label: 'Last name',
    value: value => value,
  },
  {
    id: 'email',
    label: 'Email',
    value: value => value,
  },
  {
    id: 'membershipExpires',
    label: 'Expiry',
    value: value => format(new Date(value), 'MMM DD, YYYY'),
  },
  {
    id: 'inviteDate',
    label: 'Invite date',
    value: value => format(new Date(value), 'MMM DD, YYYY'),
  },
  {
    id: 'isActive',
    label: 'Status',
    value: value => (value ? 'Active' : 'Inactive'),
  },
  {
    id: 'membershipLevel',
    label: 'Membership',
    value: value => value,
  },
  {
    id: 'isAdmin',
    label: 'Access',
    value: value => (value ? 'Admin' : 'User'),
  },
];

const AdminManageMembersToolbarFilterList = ({
  whereList,
  setWhereList,
  ...props
}) => {
  const { data: membershipLevels } = useMembershipLevels();
  return (
    <Wrapper {...props}>
      {whereList.map(where => {
        const fieldResolver = displayFields.find(
          field => field.id === where[0],
        );
        const { label, value } = fieldResolver;
        const isMembershipLevel = where[0] === 'membershipLevel';
        return (
          <Badge
            m={1}
            color="white"
            bg="grays.400"
            key={`where-badge-${where[0]}`}
            css={{ display: 'flex', alignItems: 'center' }}
          >
            {label}: {where[1] === '<=' && 'to'}
            {where[1] === '>=' && 'from'}{' '}
            {isMembershipLevel
              ? membershipLevels?.docs?.find(doc => doc.id === where[2]).data()
                  .label
              : value(where[2])}
            &nbsp;
            <FiX
              size={16}
              role="button"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                setWhereList(
                  whereList.filter(_where => !isEqual(where, _where)),
                )
              }
            />
          </Badge>
        );
      })}
    </Wrapper>
  );
};

export default AdminManageMembersToolbarFilterList;
