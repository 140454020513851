import React, { useState, useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import AdminMemberList from './Member/List';
import { getQuery } from './utils';
import Alert from '../../../Alert';
import AdminManageMembersToolbar from './Toolbar';
import Pagination from '../../../Pagination';
import { updateMember } from '../../../../utils/hooks/members/useUpdateMember';

/* omit members with no invitation */
const defaultWhereList = [['inviteDate', '>=', new Date('1000-01-01')]];

const ManageUsers = () => {
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [orderBy, setOrderBy] = useState();
  const [whereList, setWhereList] = useState([]);
  const [updateByWhereList, setUpdateByWhereList] = useState(false);
  const [limit] = useState(10);
  const [page, setPage] = useState(0);
  const [startAt, setStartAt] = useState(null);
  const [totalData, totalDataLoading] = useCollection(
    getQuery(
      { orderBy },
      whereList.length > 0 || orderBy ? whereList : defaultWhereList,
    ),
  );
  const [data, loading, error] = useCollection(
    getQuery(
      { orderBy, limit, startAt },
      whereList.length > 0 || orderBy ? whereList : defaultWhereList,
    ),
    {
      idField: 'id',
    },
  );
  // const [updateMember] = useFirebaseCallable('updateMember');
  const _updateMember = values => {
    return updateMember({
      uidList: selectedMembers,
      ...(updateByWhereList ? { whereList } : {}),
      ...values,
    });
  };
  const isLoading = totalDataLoading || loading;
  const docs = data?.docs;
  const goToPage = page => {
    setPage(page);
    const startAtDoc = totalData?.docs[limit * page];
    setStartAt(startAtDoc);
  };
  const totalSize = totalData?.size;
  const pageCount = Math.ceil(totalSize / limit);
  const members = docs?.map(doc => doc.data());
  const pageSize = members?.length;
  useEffect(() => {
    setSelectedMembers([]);
    setUpdateByWhereList(false);
  }, [whereList, orderBy]);
  return (
    <div>
      {error && <Alert type="danger">{error?.message || error}</Alert>}
      <AdminManageMembersToolbar
        updateMember={_updateMember}
        setWhereList={setWhereList}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        updateByWhereList={updateByWhereList}
        selectAllByWhereList={setUpdateByWhereList}
        whereList={whereList}
        total={totalSize}
        pageSize={pageSize}
        mb={3}
      />
      <AdminMemberList
        members={members}
        onOrderBy={setOrderBy}
        orderBy={orderBy}
        isLoading={isLoading}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        updateByWhereList={updateByWhereList}
      />
      <Pagination
        pages={[...Array(pageCount || 0).keys()].map(page => page + 1)}
        onChange={page => goToPage(page - 1)}
        value={page + 1}
        my={3}
      />
    </div>
  );
};

export default ManageUsers;
