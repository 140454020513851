import React, { useState } from 'react';
import { useDialogState, DialogDisclosure } from 'reakit/Dialog';
import { DialogBackdrop, Dialog } from '../../../../../Dialog';
import Input from '../../../../../Input';
import { Box, Flex } from 'rebass';
import Hr from '../../../../../Layout/Hr';
import Button from '../../../../../Button';
import useCreateMembershipLevel from '../../../../../../utils/hooks/members/useCreateMembershipLevel';
import ConfirmationPrompt from '../../../ManageUsers/Toolbar/Edit/Prompt';
import MembersAdminSettingsMembershipLevelsItemPrismicAlert from '../PrismicAlert';

const MembersAdminSettingsMembershipLevelsCreate = () => {
  const dialog = useDialogState({ modal: true });
  const [input, setInput] = useState('');
  const [didConfirm, setDidConfirm] = useState(false);
  const [createMembershipLevel] = useCreateMembershipLevel();
  return (
    <>
      <DialogDisclosure {...dialog}>
        <Button>Create new level</Button>
      </DialogDisclosure>
      <DialogBackdrop {...dialog}>
        <Dialog maxWidth={480} {...dialog}>
          {didConfirm ? (
            <ConfirmationPrompt
              onHide={() => {
                dialog.hide();
                setDidConfirm(false);
              }}
              onConfirm={() => createMembershipLevel({ label: input })}
              showAlert={didConfirm}
              confirmationMessage="Membership level is being created"
            >
              <MembersAdminSettingsMembershipLevelsItemPrismicAlert
                label={input}
              />
            </ConfirmationPrompt>
          ) : (
            <>
              <form onSubmit={e => e.preventDefault()}>
                <Box p={[3, 4]}>
                  <Input
                    inline
                    label="Create membership level"
                    onChange={e => setInput(e.target.value)}
                    value={input}
                    error={!input.length ? 'Required' : undefined}
                    touched={input !== ''}
                  />
                </Box>
                <Hr width={1} height={2} />
                <Flex p={3} justifyContent="flex-end">
                  <Button
                    disabled={!input.length}
                    variant="minimal"
                    onClick={() => setDidConfirm(true)}
                  >
                    Continue
                  </Button>
                </Flex>
              </form>
            </>
          )}
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

export default MembersAdminSettingsMembershipLevelsCreate;
