import React, { useState } from 'react';
import { useDialogState, DialogDisclosure } from 'reakit/Dialog';
import { Dialog, DialogBackdrop } from '../../../../../../Dialog';
import Select from '../../../../../../Select';
import Text from '../../../../../../Text';
import ConfirmationPrompt from '../Prompt';
import useMembershipLevels from '../../../../../../../utils/hooks/members/useMembershipLevels';

const AdminManagerMembersToolbarEditMembershipLevel = ({
  onUpdate,
  selectedLength,
  updateByWhereList,
}) => {
  const { data: membershipLevels } = useMembershipLevels();
  const [membershipLevel, setMembershipLevel] = useState();
  const dialog = useDialogState({ modal: true });
  return (
    <>
      <DialogDisclosure {...dialog}>Membership level</DialogDisclosure>
      <DialogBackdrop {...dialog}>
        <Dialog maxWidth={480} {...dialog}>
          <ConfirmationPrompt
            onHide={dialog.hide}
            confirmationMessage="Membership level is being updated"
            onConfirm={() => onUpdate({ membershipLevel })}
            disabled={!membershipLevel}
          >
            <Text mb={3} fontWeight="500" fontSize={2}>
              Update membership level for{' '}
              {updateByWhereList
                ? 'all members that match this set of filters'
                : `${selectedLength} member${selectedLength > 1 ? 's' : ''}`}
            </Text>
            <Select
              variant="secondary"
              value={membershipLevel}
              onChange={e => setMembershipLevel(e.target.value)}
            >
              {membershipLevels?.docs?.map(doc => (
                <option key={doc.id} value={doc.id}>
                  {doc.data().label}
                </option>
              ))}
            </Select>
          </ConfirmationPrompt>
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

export default AdminManagerMembersToolbarEditMembershipLevel;
