import firebase from '../../../../firebase/config';

const dynamicQuery = (query, options = {}) => {
  Object.keys(options).forEach(option => {
    const value = options[option];
    if (value) {
      query = Array.isArray(value)
        ? query[option].apply(query, value)
        : query[option].call(query, value);
    }
  });
  return query;
};

const applyWhereList = (query, whereList) => {
  whereList.forEach(where => {
    query = query.where.apply(query, where);
  });
  return query;
};

export const getQuery = (options, whereList = [], collection = 'members') => {
  let query = firebase?.firestore().collection(collection);
  query = applyWhereList(query, whereList);
  query = dynamicQuery(query, options);
  return query;
};
