import { format } from 'date-fns';

export const memberFields = [
  { id: 'email', label: 'Email' },
  { id: 'firstName', label: 'First name' },
  { id: 'lastName', label: 'Last name' },
  { id: 'membershipExpires', label: 'Membership expiry date' },
  { id: 'membershipLevel', label: 'Membership level' },
  { id: 'isActive', label: 'Status' },
];

export const displayFields = [
  {
    id: 'firstName',
    label: 'Name',
    value: member => `${member.firstName} ${member.lastName}`,
    width: 1 / 8,
  },
  {
    id: 'email',
    label: 'Email',
    value: member => member.email,
    width: 1 / 4,
  },
  {
    id: 'inviteDate',
    label: 'Invite date',
    value: member =>
      member.inviteDate
        ? format(new Date(member.inviteDate.toDate()), 'MMM DD, YYYY')
        : null,
    width: 1 / 8,
  },
  {
    id: 'membershipExpires',
    label: 'Expiry',
    value: member =>
      member.membershipExpires
        ? format(new Date(member.membershipExpires.toDate()), 'MMM DD, YYYY')
        : null,
    width: 1 / 8,
  },
  {
    id: 'isActive',
    label: 'Status',
    value: member => (member.isActive ? 'Active' : 'Inactive'),
    width: 1 / 8,
  },
  {
    id: 'membershipLevel',
    label: 'Membership',
    value: member => member.membershipLevel,
    width: 1 / 5,
  },
  {
    id: 'isAdmin',
    label: 'Access',
    value: member => (member.isAdmin ? 'Admin' : 'User'),
    width: 1 / 9,
  },
];
