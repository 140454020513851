import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import Input from '../../../Input';
import Text from '../../../Text';
import Select from '../../../Select';
import { Flex, Box } from 'rebass';
import Button from '../../../Button';
import useFirebaseCallable from '../../../../utils/hooks/members/useFirebaseCallable';
import Alert from '../../../Alert';
import Card from '../../../Card';
import { FiPlusCircle } from 'react-icons/fi';
import styled from 'styled-components';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { space } from 'styled-system';
import useMembershipLevels from '../../../../utils/hooks/members/useMembershipLevels';
import Checkbox from '../../../Checkbox';

const StyledDatePicker = styled(DatePicker)`
  ${space}
  background: #f3f3f3;
  border-radius: 100px;
  border: 0;
`;

StyledDatePicker.defaultProps = {
  px: 4,
  py: 3,
};

const initialBatchState = {
  enabled: false,
  value: '',
};

const SendInvites = () => {
  const { data: membershipLevels } = useMembershipLevels();
  const [count, setCount] = useState(1);
  const [sendInvites, { data, loading, error }] = useFirebaseCallable(
    'sendInvites',
  );
  const [batchExpiry, setBatchExpiry] = useState(initialBatchState);
  const [batchMembershipLevel, setBatchMembershipLevel] = useState(
    initialBatchState,
  );
  const onSend = (data, resetForm) => {
    const keys = Object.keys(data);
    const invites = [...Array(Number(count))].map(() => ({}));
    keys.forEach(key => {
      const splitKey = key.split('-');
      const idx = splitKey[1];
      const prop = splitKey[2];
      invites.splice();
      const value =
        prop === 'membershipExpires' ? data[key].toISOString() : data[key];
      invites[idx][prop] = value;
    });
    if (batchMembershipLevel.enabled) {
      invites.forEach((_, idx) => {
        invites[idx]['membershipLevel'] = batchMembershipLevel.value;
      });
    }
    if (batchExpiry.enabled) {
      invites.forEach((_, idx) => {
        invites[idx]['membershipExpires'] = batchExpiry.value.toISOString();
      });
    }
    sendInvites({ invites }).then(() =>
      resetForm(
        [...Array(Number(count)).keys()].reduce((acc, val, idx) => {
          acc[`invitee-${idx}-firstName`] = '';
          acc[`invitee-${idx}-lastName`] = '';
          acc[`invitee-${idx}-email`] = '';
          acc[`invitee-${idx}-membershipLevel`] = '';
          acc[`invitee-${idx}-membershipExpires`] = '';
          return acc;
        }, {}),
      ),
    );
  };
  return (
    <>
      <Formik
        enableReinitialize
        onSubmit={(values, { resetForm }) => onSend(values, resetForm)}
        initialValues={{}}
      >
        <Form>
          <Flex m={-2}>
            <Input
              label="Number of invitees"
              type="number"
              min={1}
              value={count}
              onChange={e => setCount(e.target.value)}
              mb={[4, 5]}
              m={2}
            />
            <Box p={2}>
              <Checkbox
                label="Batch membership level"
                id="batch-membership-level"
                onChange={() =>
                  setBatchMembershipLevel({
                    ...batchMembershipLevel,
                    enabled: !batchMembershipLevel.enabled,
                  })
                }
              />
              <Select
                mt={3}
                disabled={!batchMembershipLevel.enabled}
                type="text"
                value={batchMembershipLevel.value}
                required={batchMembershipLevel.enabled}
                onChange={e =>
                  setBatchMembershipLevel({
                    ...batchMembershipLevel,
                    value: e.target.value,
                  })
                }
              >
                <option value="" />
                {membershipLevels?.docs?.map(doc => (
                  <option
                    key={`batch-membership-option-${doc.id}`}
                    value={doc.id}
                  >
                    {doc.data().label}
                  </option>
                ))}
              </Select>
            </Box>
            <Box p={2}>
              <Checkbox
                label="Batch membership expiry"
                id="batch-membership-expiry"
                onChange={() =>
                  setBatchExpiry({
                    ...batchExpiry,
                    enabled: !batchExpiry.enabled,
                  })
                }
              />
              <Input
                as={DatePicker}
                mt={3}
                required={batchExpiry.enabled}
                disabled={!batchExpiry.enabled}
                selected={batchExpiry.value}
                onChange={e => setBatchExpiry({ ...batchExpiry, value: e })}
              />
            </Box>
          </Flex>
          {(batchExpiry.enabled || batchMembershipLevel.enabled) && (
            <Alert mb={3} type="warning">
              Batch options will be applied once submitting.
            </Alert>
          )}
          <Flex flexDirection="column" my={-4} mb={3}>
            {[...Array(Number(count)).keys()].map((_, idx) => (
              <Box key={`invitee-${idx}`} py={4}>
                <Card p={4}>
                  <Text mb={2} fontSize={3}>
                    Invitee #{idx + 1}
                  </Text>
                  <Flex flexWrap="wrap" m={-2}>
                    <Field name={`invitee-${idx}-firstName`}>
                      {({ field }) => (
                        <Box p={2}>
                          <Input
                            variant="secondary"
                            required
                            label="First name"
                            type="text"
                            {...field}
                          />
                        </Box>
                      )}
                    </Field>
                    <Field name={`invitee-${idx}-lastName`}>
                      {({ field }) => (
                        <Box p={2}>
                          <Input
                            variant="secondary"
                            required
                            label="Last name"
                            type="text"
                            {...field}
                          />
                        </Box>
                      )}
                    </Field>
                    <Field name={`invitee-${idx}-email`}>
                      {({ field }) => (
                        <Box p={2}>
                          <Input
                            variant="secondary"
                            required
                            label="Email"
                            type="email"
                            {...field}
                          />
                        </Box>
                      )}
                    </Field>
                    <Field name={`invitee-${idx}-membershipLevel`}>
                      {({ field }) => (
                        <Box p={2}>
                          <Text variant="secondary" mb={3}>
                            Membership level
                          </Text>
                          <Select
                            mb={2}
                            variant="secondary"
                            required={!batchMembershipLevel.enabled}
                            label="Membership level"
                            disabled={batchMembershipLevel.enabled}
                            type="text"
                            {...field}
                            value={field.value || batchMembershipLevel}
                          >
                            <option value="" />
                            {membershipLevels?.docs?.map(doc => (
                              <option
                                key={`attendee-${idx}-membership-option-${doc.id}`}
                                value={doc.id}
                              >
                                {doc.data().label}
                              </option>
                            ))}
                          </Select>
                        </Box>
                      )}
                    </Field>
                    <Field name={`invitee-${idx}-membershipExpires`}>
                      {({ field, form }) => {
                        return (
                          <Box p={2}>
                            <Text variant="secondary" mb={3}>
                              Membership expiry date
                            </Text>
                            <Input
                              variant="secondary"
                              disabled={batchExpiry.enabled}
                              as={DatePicker}
                              mb={2}
                              required={!batchExpiry.enabled}
                              selected={field.value}
                              onChange={e =>
                                form.setFieldValue(
                                  `invitee-${idx}-membershipExpires`,
                                  e,
                                )
                              }
                            />
                          </Box>
                        );
                      }}
                    </Field>
                  </Flex>
                </Card>
              </Box>
            ))}
          </Flex>
          <Button
            mb={4}
            py={2}
            px={3}
            type="button"
            onClick={() => setCount(Number(count) + 1)}
            variant="outline"
            width={1}
          >
            <FiPlusCircle size={24} />
          </Button>

          {error && (
            <Alert mb={3} type="danger">
              {error?.message}
            </Alert>
          )}
          {data && (
            <Alert mb={3} type="success">
              Invites sent!
            </Alert>
          )}
          <Button disabled={loading} type="submit">
            {loading
              ? 'Loading...'
              : `Send ${count} invite${count > 1 ? 's' : ''}`}
          </Button>
        </Form>
      </Formik>
    </>
  );
};

export default SendInvites;
