import React from 'react';
import Text from '../../../../../Text';
import { useCopyToClipboard } from 'react-use';
import Input from '../../../../../Input';
import { FiCopy } from 'react-icons/fi';

const MembersAdminSettingsMembershipLevelsItemPrismicAlert = ({ label }) => {
  const [state, copyToClipboard] = useCopyToClipboard();
  return (
    <div>
      <Text fontWeight="500" mb={3}>
        Just a reminder
      </Text>
      <Text mb={3}>Make sure to update Prismic with the new level name:</Text>
      <Input inline value={label} />
      <FiCopy
        style={{ cursor: 'pointer' }}
        onClick={() => copyToClipboard(label)}
      />
      {state.error ? (
        <Text mt={1}>Unable to copy value: {state.error.message}</Text>
      ) : (
        state.value && <Text mt={1}>Copied!</Text>
      )}
    </div>
  );
};

export default MembersAdminSettingsMembershipLevelsItemPrismicAlert;
