import React, { useState } from 'react';
import { useDialogState, DialogDisclosure } from 'reakit/Dialog';
import { Dialog, DialogBackdrop } from '../../../../../../Dialog';
import Text from '../../../../../../Text';
import ReactDatePicker from 'react-datepicker';
import Input from '../../../../../../Input';
import ConfirmationPrompt from '../Prompt';
import lazyFirebase from '../../../../../../../firebase/config';

const AdminManagerMembersToolbarEditMembershipExpiry = ({
  onUpdate,
  selectedLength,
  updateByWhereList,
}) => {
  const [date, setDate] = useState();
  const dialog = useDialogState({ modal: true });
  return (
    <>
      <DialogDisclosure {...dialog}>Membership expiry date</DialogDisclosure>
      <DialogBackdrop {...dialog}>
        <Dialog maxWidth={480} overflow="visible" {...dialog}>
          <ConfirmationPrompt
            onHide={dialog.hide}
            confirmationMessage="Membership expiry date is being updated"
            onConfirm={() =>
              onUpdate({
                membershipExpires: lazyFirebase.firestore.Timestamp.fromDate(
                  date,
                ),
              })
            }
            disabled={!date}
          >
            <Text fontWeight="500" mb={3} fontSize={2}>
              Update membership expiry date for{' '}
              {updateByWhereList
                ? 'all members that match this set of filters'
                : `${selectedLength} member${selectedLength > 1 ? 's' : ''}`}
            </Text>
            <Input
              variant="secondary"
              as={ReactDatePicker}
              selected={date}
              onSelect={setDate}
            />
          </ConfirmationPrompt>
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

export default AdminManagerMembersToolbarEditMembershipExpiry;
