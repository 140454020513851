import React from 'react';
import { usePopoverState, PopoverDisclosure } from 'reakit/Popover';
import Button from '../../../../../Button';
import { FiFilter } from 'react-icons/fi';
import { Popover } from '../../../../../Popover';
import { Box, Flex } from 'rebass';
import { Form, Formik, Field } from 'formik';
import Input from '../../../../../Input';
import Select from '../../../../../Select';
import { statusLevels } from '../../../../constants';
import Text from '../../../../../Text';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import Hr from '../../../../../Layout/Hr';
import useMembershipLevels from '../../../../../../utils/hooks/members/useMembershipLevels';

const InputWrapper = styled(Box)``;

InputWrapper.defaultProps = { width: [1, 1 / 2], p: 2 };

const TextButton = styled(Text).attrs({ role: 'button' })`
  font-weight: 500;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const validateForm = values => {
  let errors = {};
  const filledKeys = Object.keys(values).filter(key => Boolean(values[key]));
  filledKeys.forEach(key => {
    if (
      filledKeys.some(key => key.includes('membershipExpires')) &&
      filledKeys.some(key => key.includes('inviteDate'))
    ) {
      errors[key] = 'Please filter by either Membership expiry or Invite date';
    }
  });
  return errors;
};

const AdminManagerMembersToolbarEdit = ({ onFilter, ...props }) => {
  const { data: membershipLevels } = useMembershipLevels();
  const popover = usePopoverState({ placement: 'bottom-end' });
  const onSubmit = (values, { setSubmitting }) => {
    const whereList = Object.keys(values)
      .filter(
        key =>
          values[key] !== '' &&
          values[key] !== null &&
          values[key] !== undefined,
      )
      .map(key => {
        if (key.includes('From')) {
          return [key.replace('From', ''), '>=', values[key]];
        } else if (key.includes('To')) {
          return [key.replace('To', ''), '<=', values[key]];
        }
        return [key, '==', values[key]];
      });
    setSubmitting(false);
    onFilter(whereList);
    popover.hide();
  };
  return (
    <Box {...props}>
      <PopoverDisclosure {...popover}>
        <Button
          variant="white"
          px={3}
          py={2}
          css={{ display: 'flex', alignItems: 'center' }}
        >
          <FiFilter size={16} />
          &nbsp; Filter
        </Button>
      </PopoverDisclosure>
      <Popover
        p={0}
        {...popover}
        style={{ zIndex: 4, maxWidth: '100%' }}
        mx={[3, 0]}
      >
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            isActive: '',
            membershipExpiresFrom: '',
            membershipExpiresTo: '',
            inviteDateFrom: '',
            inviteDateTo: '',
            membershipLevel: '',
          }}
          validate={validateForm}
        >
          {({ handleReset }) => (
            <Form>
              <Flex flexWrap="wrap" maxWidth={640} m={-2} p={3}>
                <Field name="firstName">
                  {({ field }) => (
                    <InputWrapper>
                      <Input
                        inline
                        width={1}
                        variant="secondary"
                        label="First name"
                        {...field}
                      />
                    </InputWrapper>
                  )}
                </Field>
                <Field name="lastName">
                  {({ field }) => (
                    <InputWrapper>
                      <Input
                        inline
                        width={1}
                        variant="secondary"
                        label="Last name"
                        {...field}
                      />
                    </InputWrapper>
                  )}
                </Field>
                <Field name="email">
                  {({ field }) => (
                    <InputWrapper>
                      <Input
                        inline
                        width={1}
                        variant="secondary"
                        label="Email"
                        type="email"
                        {...field}
                      />
                    </InputWrapper>
                  )}
                </Field>
                <Field name="isActive">
                  {({ field, form }) => (
                    <InputWrapper>
                      <Text mb={3} variant="secondary">
                        Status
                      </Text>
                      <Select
                        inline
                        width={1}
                        variant="secondary"
                        {...field}
                        onChange={e =>
                          form.setFieldValue(
                            'isActive',
                            e.target.value === ''
                              ? e.target.value
                              : e.target.value === 'true',
                          )
                        }
                      >
                        <option value=""></option>
                        {statusLevels.map(level => (
                          <option
                            key={`filter-status-${level.label}`}
                            value={level.value}
                          >
                            {level.label}
                          </option>
                        ))}
                      </Select>
                    </InputWrapper>
                  )}
                </Field>
                <Flex p={2} m={-1} width={[1, 1 / 2]} flexWrap="wrap">
                  <Text variant="secondary" p={1} width={1}>
                    Invite date
                  </Text>
                  <Field name="inviteDateFrom">
                    {({ field, form, meta }) => (
                      <InputWrapper width={[1 / 2]} p={1}>
                        <Input
                          inline
                          width={1}
                          label="From"
                          labelFontSize={0}
                          variant="secondary"
                          // eslint-disable-next-line
                          as={({ inline, ...props }) => (
                            <ReactDatePicker {...props} />
                          )}
                          selected={field.value ? new Date(field.value) : ''}
                          onSelect={e => form.setFieldValue(field.name, e)}
                          {...meta}
                        />
                      </InputWrapper>
                    )}
                  </Field>
                  <Field name="inviteDateTo">
                    {({ field, form, meta }) => (
                      <InputWrapper width={[1 / 2]} p={1}>
                        <Input
                          inline
                          width={1}
                          label="To"
                          labelFontSize={0}
                          variant="secondary"
                          // eslint-disable-next-line
                          as={({ inline, ...props }) => (
                            <ReactDatePicker {...props} />
                          )}
                          selected={field.value ? new Date(field.value) : ''}
                          onSelect={e => form.setFieldValue(field.name, e)}
                          {...meta}
                        />
                      </InputWrapper>
                    )}
                  </Field>
                </Flex>
                <Flex p={2} m={-1} width={[1, 1 / 2]} flexWrap="wrap">
                  <Text variant="secondary" p={1} width={1}>
                    Membership expiry
                  </Text>
                  <Field name="membershipExpiresFrom">
                    {({ field, form, meta }) => (
                      <InputWrapper width={[1 / 2]} p={1}>
                        <Input
                          inline
                          width={1}
                          label="From"
                          labelFontSize={0}
                          variant="secondary"
                          // eslint-disable-next-line
                          as={({ inline, ...props }) => (
                            <ReactDatePicker {...props} />
                          )}
                          selected={field.value ? new Date(field.value) : ''}
                          onSelect={e => form.setFieldValue(field.name, e)}
                          {...meta}
                        />
                      </InputWrapper>
                    )}
                  </Field>
                  <Field name="membershipExpiresTo">
                    {({ field, form, meta }) => (
                      <InputWrapper width={[1 / 2]} p={1}>
                        <Input
                          inline
                          width={1}
                          label="To"
                          labelFontSize={0}
                          variant="secondary"
                          // eslint-disable-next-line
                          as={({ inline, ...props }) => (
                            <ReactDatePicker {...props} />
                          )}
                          selected={field.value ? new Date(field.value) : ''}
                          onSelect={e => form.setFieldValue(field.name, e)}
                          {...meta}
                        />
                      </InputWrapper>
                    )}
                  </Field>
                </Flex>
                <Field name="membershipLevel">
                  {({ field }) => (
                    <InputWrapper>
                      <Text mb={3} variant="secondary">
                        Membership level
                      </Text>
                      <Select inline variant="secondary" width={1} {...field}>
                        <option value=""></option>
                        {membershipLevels?.docs?.map(doc => (
                          <option
                            key={`filter-membership-${doc.id}`}
                            value={doc.id}
                          >
                            {doc.data().label}
                          </option>
                        ))}
                      </Select>
                    </InputWrapper>
                  )}
                </Field>
              </Flex>
              <Hr width={1} height={2} />
              <Flex p={3} m={-3} justifyContent="flex-end">
                <TextButton
                  color="secondary"
                  p={3}
                  type="button"
                  onClick={() => {
                    handleReset();
                    popover.hide();
                  }}
                >
                  Cancel
                </TextButton>
                <TextButton
                  as="button"
                  type="submit"
                  color="chestnutRose"
                  p={3}
                  // onClick={handleSubmit}
                >
                  Filter
                </TextButton>
              </Flex>
            </Form>
          )}
        </Formik>
      </Popover>
    </Box>
  );
};

export default AdminManagerMembersToolbarEdit;
