import React from 'react';
import { Flex, Box } from 'rebass';
import Text from '../../../../Text';
import AdminManagerMembersToolbarEditButton from './Edit/Button';
import AdminManagerMembersToolbarEdit from './Filter';
import Badge from '../../../../Badge';
import { FiX } from 'react-icons/fi';
import Alert from '../../../../Alert';
import AdminManageMembersToolbarFilterList from './FilterList';

const AdminManageMembersToolbar = ({
  total,
  pageSize,
  updateMember,
  setWhereList,
  selectedMembers,
  setSelectedMembers,
  updateByWhereList,
  selectAllByWhereList,
  whereList,
  ...props
}) => {
  const selectedLength = selectedMembers?.length;
  const areAllSelectedButJustInPage =
    total > pageSize && pageSize === selectedLength;
  return (
    <Box {...props}>
      <Flex
        alignItems="center"
        flexWrap="wrap"
        justifyContent="space-between"
        m={-2}
      >
        <Flex flex={1} flexWrap="wrap" alignItems="center" m={-2} p={2}>
          <Text
            fontWeight="500"
            p={2}
            display={selectedLength ? ['none', null, 'auto'] : undefined}
          >
            {total} members
          </Text>
          {(!!selectedLength || updateByWhereList) && (
            <Badge
              bg="white"
              m={2}
              fontWeight="500"
              css={{ display: 'inline-flex', alignItems: 'center' }}
            >
              {updateByWhereList
                ? 'Selected all by filters'
                : `${selectedLength} selected`}
              &nbsp;
              <FiX
                size={16}
                role="button"
                onClick={() => {
                  setSelectedMembers([]);
                  selectAllByWhereList(false);
                }}
                style={{ cursor: 'pointer' }}
              />
            </Badge>
          )}
        </Flex>
        <AdminManagerMembersToolbarEdit onFilter={setWhereList} p={2} />
        <AdminManagerMembersToolbarEditButton
          updateMember={updateMember}
          selectedLength={selectedLength}
          updateByWhereList={updateByWhereList}
          p={2}
        />
      </Flex>
      {!!whereList.length && (
        <AdminManageMembersToolbarFilterList
          p={1}
          mt={2}
          whereList={whereList}
          setWhereList={setWhereList}
        />
      )}
      {!updateByWhereList && areAllSelectedButJustInPage && !!whereList.length && (
        <Alert
          mt={2}
          type="warning"
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <Text color="white">
            Selected all members in this page,{' '}
            <Text
              as="a"
              color="valhalla"
              onClick={selectAllByWhereList}
              href="#!"
            >
              click here
            </Text>{' '}
            to select all matches
          </Text>
        </Alert>
      )}
    </Box>
  );
};

export default AdminManageMembersToolbar;
