import React, { useRef } from 'react';
import { displayFields } from '../../utils';
import { Flex, Box } from 'rebass';
import Text from '../../../../../../Text';
import styled from 'styled-components';
import { FiSquare, FiCheckSquare } from 'react-icons/fi';

const Wrapper = styled(Box).attrs({
  bg: 'grays.200',
})``;

const Label = styled(Text).attrs({
  role: 'button',
})`
  font-weight: 500;
  cursor: pointer;
`;

const AdminMemberListHeader = ({
  orderBy,
  onOrderBy,
  onSelect,
  areAllMembersSelected,
}) => {
  const _onOrderBy = value => {
    const order = orderBy?.[1];
    if (orderBy?.[0] === value) {
      if (order === 'asc') {
        onOrderBy([value, 'desc']);
      } else {
        onOrderBy(null);
      }
    } else {
      onOrderBy([value, 'asc']);
    }
  };
  const iconProps = {
    onClick: onSelect,
    size: 24,
    style: { display: 'inline-flex', cursor: 'pointer' },
  };
  const checkbox = useRef(null);
  const bg = 'grays.200';
  return (
    <Wrapper p={3}>
      <Flex m={-3} alignItems="center">
        <Box
          ref={checkbox}
          p={3}
          bg={bg}
          css={{ display: 'inline-flex', position: 'sticky', left: 0 }}
        >
          {areAllMembersSelected ? (
            <FiCheckSquare {...iconProps} />
          ) : (
            <FiSquare {...iconProps} />
          )}
        </Box>
        {displayFields.map((field, idx) => {
          const isOrderBy = orderBy?.[0] === field.id;
          return (
            <Label
              onClick={() => _onOrderBy(field.id)}
              p={3}
              width={field.width}
              key={`header-${field.id}`}
              bg={isOrderBy ? 'grays.100' : bg}
              style={
                idx === 0
                  ? { position: 'sticky', left: checkbox?.current?.scrollWidth }
                  : {}
              }
            >
              {field.label.toUpperCase()}{' '}
              {isOrderBy && (orderBy?.[1] === 'asc' ? '▴' : '▾')}
            </Label>
          );
        })}
      </Flex>
    </Wrapper>
  );
};

export default AdminMemberListHeader;
